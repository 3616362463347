
/**
 * @file store入口文件
 * @author dongkunshan(dongkunshan210816@credithc.com)
 */

import Vue  from 'vue'
import Vuex from 'vuex'
import user from './user'

/** 插件注册 */
Vue.use(Vuex)

/** 创建store */
const store = new Vuex.Store({
  strict: true,
  state: {
    loading: false,
    loadingText: '',
    showNoSignal: false,
  },
  mutations: {
    showNoSignalPage: (state) => {
      state.showNoSignal = true
    },
    hideNoSignalPage: (state) => {
      state.showNoSignal = false
    },
    showLoading (state, value) {
      state.loading = true
      state.loadingText = value
    },
    hideLoading (state) {
      state.loading = false
      state.loadingText = ''
    },
  },
  // 子模块
  modules: {
    user
  }
})

export default store
