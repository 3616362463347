// 输入金额过滤, amount限制两位小数，Amount整数
export function checkNumber(value, type) {
  // 保留小数点2位
  let val = value
  let newVal = ""
  if (val === Number(val)) {
    if (["Amount", "integer"].includes(type)) {
      newVal = val.replace(/\D*/g, "")
    } else {
      let floatIndex = val.indexOf(".")
      if (floatIndex === -1 || val.length < floatIndex + 2) {
        newVal = val
      } else {
        newVal = val.substr(0, floatIndex + 3)
      }
    }
  } else {
    newVal = ""
  }
  return newVal
}

export function encryptPhoneNumber(phone) {
  const reg = /(\d{3})\d{4}(\d{4})/ // 正则表达式
  return String(phone).replace(reg, "$1****$2") // 返回加密后的电话号码
}

export const TempCache = {
  setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  getItem(key) {
    const item = localStorage.getItem(key)
    return JSON.parse(item)
  },
  getStrItem(key) {
    const item = localStorage.getItem(key)
    return item
  },
  removeItem(key) {
    return localStorage.removeItem(key)
  },
  clear() {
    localStorage.clear()
  },
}

// 取url参数
export const queryString = (str = "", url = "") => {
  /* eslint-disable */
  url = url || window.location.href;
  const _pa = url.substring(url.indexOf("?") + 1);
  const _arrS = _pa.split("&");
  const _rs = {};
  for (let i = 0, _len = _arrS.length; i < _len; i++) {
    const pos = _arrS[i].indexOf("=");
    if (pos === -1) {
      continue;
    }
    const key = _arrS[i].substring(0, pos);
    const value = decodeURIComponent(_arrS[i].substring(pos + 1));
    _rs[key] = value;
  }
  if (str) {
    return _rs[str];
  }
  return _rs;
};

function compatibleAgeFormat(date) {
  return date.length === 1 ? 0 + date : date;
}

//根据生日计算当前的年龄
export function getAge(strBirthday) {
  var returnAge;
  var strBirthdayArr = strBirthday.split("-");
  var birthYear = strBirthdayArr[0];
  var birthMonth = compatibleAgeFormat(strBirthdayArr[1]);
  var birthDay = compatibleAgeFormat(strBirthdayArr[2]);
  const d = new Date();
  var nowYear = d.getFullYear();
  var nowMonth = d.getMonth() + 1;
  var nowDay = d.getDate();

  if (nowYear == birthYear) {
    returnAge = 0; //同年 则为0岁
  } else {
    var ageDiff = nowYear - birthYear; //年之差
    if (ageDiff > 0) {
      if (nowMonth == birthMonth) {
        var dayDiff = nowDay - birthDay; //日之差
        if (dayDiff < 0) {
          returnAge = ageDiff - 1;
        } else {
          returnAge = ageDiff;
        }
      } else {
        var monthDiff = nowMonth - birthMonth; //月之差
        if (monthDiff < 0) {
          returnAge = ageDiff - 1;
        } else {
          returnAge = ageDiff;
        }
      }
    } else {
      returnAge = -1; //出生日期不能大于今天
    }
  }
  return returnAge;
}

//保留两位小数大于等于0
export const validatorPoint = function (val) {
  return /^-?\d+\.?\d{0,2}$/.test(val) && val >= 0;
};
//身份证号
export const validatorIdCard = function (val) {
  return /^(\d{18,18}|\d{15,15}|\d{17,17}X)$/.test(val);
};
//生成uuid
export function guid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

// 计算身份证的出生日期
export function cardToBirthday(idNo, split = "-") {
  idNo = idNo.replace(/\s*/g, "");
  let tmpStr = "";
  if (idNo.length === 15) {
    tmpStr = idNo.substring(6, 12);
    tmpStr = "19" + tmpStr;
    tmpStr =
      tmpStr.substring(0, 4) +
      split +
      tmpStr.substring(4, 6) +
      split +
      tmpStr.substring(6);
  } else if (idNo.length === 18) {
    tmpStr = idNo.substring(6, 14);
    tmpStr =
      tmpStr.substring(0, 4) +
      split +
      tmpStr.substring(4, 6) +
      split +
      tmpStr.substring(6);
  }
  return tmpStr;
}

// 对身份证号进行校验
export function checkIDCard(idcode) {
  if (idcode.length !== 18) {
    return false;
  }
  // 加权因子
  const weightFactor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  // 校验码
  const checkCode = ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"];
  const code = String(idcode);
  const last = idcode[17]; // 最后一位
  const seventeen = code.substring(0, 17);
  // ISO 7064:1983.MOD 11-2 判断最后一位校验码是否正确
  const arr = seventeen.split("");
  const len = arr.length;
  let num = 0;
  for (let i = 0; i < len; i++) {
    num = num + arr[i] * weightFactor[i];
  }
  // 获取余数
  const resisue = num % 11;
  const lastNo = checkCode[resisue];
  // 身份证号格式的正则思路
  const idcardPatter =
    /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;
  // 判断格式是否正确
  const format = idcardPatter.test(idcode);
  // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
  return last === lastNo && format;
}

/** 根据身份证号获取性别和精确年龄 */
export function analyzeIDCard(IDCard) {
  // 获取用户身份证号码
  let userCard = IDCard;
  // 如果身份证号码为undefind则返回空
  if (!userCard) {
    return false;
  }
  // 获取出生年月日
  let yearBirth = userCard.substring(6, 10);
  let monthBirth = userCard.substring(10, 12);
  let dayBirth = userCard.substring(12, 14);
  // 获取当前年月日并计算年龄
  let myDate = new Date();
  let monthNow = myDate.getMonth() + 1;
  let dayNow = myDate.getDate();
  let age = myDate.getFullYear() - yearBirth;
  if (monthNow < monthBirth || (monthNow == monthBirth && dayNow < dayBirth)) {
    age--;
  }
  if (age >= 18) {
    return true;
  }
  return false;
}

/**
 * @desc 函数防抖
 * @param func 函数
 * @param wait 延迟执行毫秒数
 * @param immediate true 表立即执行，false 表非立即执行
 */
export function debounce(func, delay, immediate) {
  // 双剑合璧版
  let timer;
  return function () {
    let context = this;
    if (timer) {
      clearTimeout(timer);
    }
    if (immediate) {
      let callNow = !timer;
      timer = setTimeout(() => {
        timer = null;
      }, delay);
      if (callNow) {
        func.apply(context, arguments);
      }
    } else {
      timer = setTimeout(() => {
        func.apply(context, arguments);
      }, delay);
    }
  };
}

/**
 * @description 识别手机号或邮箱或身份证并进行脱敏
 * @param {string} value
 */
export const desensitize = (value) => {
  if (typeof value !== "string") {
    return value;
  }

  // 匹配手机号并脱敏
  if (/^1[3456789]\d{9}$/.test(value)) {
    return value.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
  }
  // 匹配邮箱并脱敏
  if (/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)) {
    const parts = value.split("@");
    const username = parts[0];
    const domain = parts[1];
    const usernameLen = username.length;
    const usernameStart = Math.floor(usernameLen / 2);
    const usernameEnd =
      usernameLen % 2 === 0 ? usernameStart : usernameStart + 1;
    const usernameMasked =
      username.substring(0, usernameStart) + "*".repeat(usernameEnd);
    return usernameMasked + "@" + domain;
  }
  // 匹配身份证号码并脱敏
  if (/^\d{17}[\dXx]$/.test(value)) {
    return value.replace(/(\d{3})\d{11}(\w{4})/, "$1**********$2");
  }

  return value;
};
// 金额千分位展示
export const toThousand = (num = 0) => {
  return num.toString().replace(/\d+/, function (n) {
    return n.replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
  });
};
// 转换万元
export const transNumberToShort = (value, decimal = 0) => {
  // console.log(value)
  // if (isNaN(value)) {
  //   return
  // }
  // value=Number(value)
  // const BASE = 10000;
  // const SIZES = ["", "万", "亿", "万亿"];
  // let i = undefined;
  // let str = "";
  // if (value < BASE) {
  //   str = value.toFixed(decimal);
  // } else {
  //   i = Math.floor(Math.log(value) / Math.log(BASE));
  //   str = `${((value / Math.pow(BASE, i))).toFixed(decimal)}${SIZES[i]}`;
  // }
  // console.log(str)
  // return str;
};
