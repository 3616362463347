/* eslint-disable */
import service from "../utils/service.js"
import { TempCache } from "../utils/index.js"

const mixins = {
    methods: {     
        axios (config) {          
            let { url, method = 'post', data, params, responseType = 'json', headers = {}, isForm } = config
            if (isForm) {
                headers['Content-Type'] = 'application/x-www-form-urlencoded'
                let myForm = new FormData()
                    let key
                for (key in data) {
                    myForm.append(key, data[key])
                }
                data = myForm
            } else {
                headers['Content-Type'] = 'application/json'
            }
            headers.token = localStorage.getItem('token') || ''
            let reqData = {
                url,
                method,
                data,
                params,
                responseType,
                headers
            }
            if (method === 'get') {
                reqData.params = data
            } else {
                reqData.data = data
                reqData.params = params
            }
            return new Promise((resolve, reject) => {
                service(reqData).then(({ data, status }) => {
                    if (data && status === 200) {
                        data.code = Number(data.code)
                        if (data.code === 0) {
                            resolve(data.data || data.body)
                        } else {
                            if(data.code === -1) {
                                this.$toast(data.msg)
                            }
                            reject(data)
                        }
                    } else if (status === 401) {
                        this.$toast('登录超时，请重新登录')
                        reject(data)
                        TempCache.removeItem('token')
                        this.$router.replace({ path: "/login" })
                    } else {
                        this.$toast(data.message || `code:${data.code}`)
                        reject(data)
                    }
                }, (error) => {
                    if (error.response?.data.code === '401') { 
                        this.$toast('登录超时，请重新登录')
                        this.$router.replace({ path: "/login" })
                    } else {
                        this.$toast(error.response.data.message)
                    }
                    reject(error)
                })
            })
        }
    }
}

export default mixins
