var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "Abnormal-wrap" }, [
    _c("img", { attrs: { src: require("@/views/index/img/noSignal.png") } }),
    _vm._v(" "),
    _c("p", { staticClass: "h fMedium t-181818 f32 m mb15" }, [
      _vm._v("网络连接异常"),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "tips fRegular t-787C86 f24 mb56" }, [
      _vm._v("检查一下网络环境及设置"),
    ]),
    _vm._v(" "),
    _c("button", { staticClass: "fMedium", on: { click: _vm.tryAgain } }, [
      _vm._v("点击重试"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }