
/**
 * 部分页面需要嵌入原生中，封装和原生交互方法
 */

 const setupWebViewJavascriptBridge = (callback) => {
   // 安卓初始化
   if (window.WebViewJavascriptBridge) {
     callback()
   } else {
     document.addEventListener('WebViewJavascriptBridgeReady', () => {
       if (window.onWebViewJavascriptBridgeReady) {
         window.onWebViewJavascriptBridgeReady(window.__bridge = window.WebViewJavascriptBridge)
       }
       callback(window.WebViewJavascriptBridge)
     }, false)
   }
   // ios初始化
   if (window.WVJBCallbacks) { return window.WVJBCallbacks.push(callback) }
   window.WVJBCallbacks = [callback]
   const WVJBIframe = document.createElement('iframe')
   WVJBIframe.style.display = 'none'
   WVJBIframe.src = 'https://__bridge_loaded__'
   document.documentElement.appendChild(WVJBIframe)
   setTimeout(() => { document.documentElement.removeChild(WVJBIframe) }, 0)
   return false
 }

 // 初始化bridge
 export const init= (cb) => {
    return new Promise((resolve) => {
      setupWebViewJavascriptBridge(() => {
        resolve('sucess')
        cb && cb()
        console.log('bridge success')
      })
    })

  }

  export default {
    // js调APP方法 （参数分别为:app提供的方法名  传给app的数据  回调）
    callHandler(name, data, callback) {
        setupWebViewJavascriptBridge((bridge) => {
            bridge.callHandler(name, data, callback)
        })
    },
    // APP调js方法 （参数分别为:js提供的方法名  回调）
    registerHandler(name, callback) {
        setupWebViewJavascriptBridge((bridge) => {
            bridge.registerHandler(name, (data, responseCallback) => {
                callback(data, responseCallback)
            })
        })
    }
}

  export function IsIOS() {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { // ios
      return 'ios'
    }
    if (/(Android)/i.test(navigator.userAgent)) {
      return 'android'
    }
  }

// 判断是不是原生开发
export function isNativeApp() {
    let userAgent = navigator.userAgent // 获取用户代理字符串
    if (/(iPhone|iPad|iPod)/i.test(userAgent)) { // iOS系统
        return /Cydia/i.test(userAgent) || /Installer/i.test(userAgent) // 判断是否安装了非官方软件或者模拟器
    } else if (/Android/.test(userAgent)) { // Android系统
        return !(/Google Inc/.test(userAgent)) // 判断是否运行在谷歌服务框架内（模拟器）
    }
    return false // 其他情况默认返回false

}