<template>
    <div class="Abnormal-wrap">
       <!-- 图片展示 -->
       <img src="@/views/index/img/noSignal.png">
       <p class="h fMedium t-181818 f32 m mb15">网络连接异常</p>
       <p class="tips fRegular t-787C86 f24 mb56">检查一下网络环境及设置</p>
       <button class="fMedium" @click="tryAgain">点击重试</button>
    </div>
</template>
<script>
export default {
  name: "Abnormal",
  methods: {
    toOrginPage() {
      this.$router.replace({
        path: this.$route.query.fromPage,
        query: { src: this.$router.query.src }
      })
    },
    tryAgain() {
      // 获取当前的路由的路径，然后重新刷新该页面
      // 但是当是从我的页面和api认证页面过来的话就返回到这两个页面
      this.$route.query.fromPage ? this.toOrginPage() : this.$router.go(0)
    }
  },
}
</script>
<style lang='scss' scoped>
.Abnormal-wrap {
  height: 100vh;
  background: linear-gradient(180deg, #fff 0%, rgba(245, 246, 250, 1) 5%, rgba(245, 246, 250, 1) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 246px;
    height: 246px;
    margin: 272px 0 57px 0;
  }
  button {
    width: 240px;
    height: 82px;
    border-radius: 41px;
    opacity: 0.5;
    border: 1px solid #2261f7;
    font-size: 30px;
    color: #2261f7;
  }
}
</style>