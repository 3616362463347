var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-overlay",
    { attrs: { show: _vm.$store.state.loading } },
    [
      _c(
        "van-loading",
        {
          staticClass: "hc-loading-wrapper",
          attrs: { size: 40, vertical: "" },
        },
        [_vm._v("\n    " + _vm._s(_vm.loadingText) + "\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }