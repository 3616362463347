import axios from 'axios'
import store from '../views/index/store'

axios.defaults.headers.post["Content-Type"] = "application/json"
const service = axios.create({
    baseURL: '/apiYX',
    timeout: 10000,
    withCredentials: true
  })

// request拦截器
service.interceptors.request.use((config) => {
    return config
}, (error) => {
    Promise.reject(error)
})

// response拦截器
service.interceptors.response.use((response) => {
    return response
}, (error) => {
    // 请求失败、错误处理回调
    if (error.code == 'ECONNABORTED' && error.message.indexOf('timeout') != -1) {
        // 判断请求超时的时候跳转到超时页面  同时还需要记录当前的路由地址重新加载的时候使用
        store.commit('hideLoading')
        store.commit('showNoSignalPage')
    }
    return Promise.reject(error)
})

export default service
