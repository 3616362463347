import Vue from "vue"
import VueRouter from "vue-router"

// 首页
const Index = () => import("./pages/index.vue")
const login = () => import("./pages/login.vue")
const Health = () => import("./pages/health.vue")
const appAgreementOne = () =>
  import("./components/agreementList/appAgreementOne.vue")
const privacyAgreement = () =>
  import("./components/agreementList/privacyAgreement.vue")
const informationShare = () =>
  import("./components/agreementList/informationShare.vue") // 信息共享授权书-畅捷通
const carLoanAgreeemnt = () =>
  import("./components/agreementList/carLoanAgreeemnt.vue") // 信息共享授权书-众鼎车融
const csssLoanAgreeemnt = () =>
  import("./components/agreementList/csssLoanAgreeemnt.vue") // 信息共享授权书-长沙三寿
const toBorrow = () => import("./pages/toBorrow.vue")
const toAuthentication = () => import("./pages/toAuthentication.vue")
const toAuthenticAPI = () => import("./pages/toAuthenticAPI.vue")
const middlePage = () => import("./pages/middlePage.vue")
const myLoan = () => import("./pages/myLoan/index.vue")
const toProgressDetailAPI = () => import("./pages/toProgressDetailAPI.vue")
const toProgressResult = () => import("./pages/toProgressResult.vue")
// 以下是需要内嵌原生的页面
const toAuthentiForNative = () =>
  import("./pages/embedNative/authentication/toAuthentiForNative.vue")
const agreementList = () =>
  import("./pages/embedNative/mine/agreementList.vue")
const aboutMe = () => import("./pages/embedNative/mine/aboutMe.vue")
const helpCenter = () => import("./pages/embedNative/mine/helpCenter.vue")
const cancelAccount = () =>
  import("./pages/embedNative/setting/cancelAccount.vue")
const pcHome = () => import('./pages/pcHome/index.vue')
/** 插件注册 */
Vue.use(VueRouter)

/** 路由配置 */
const routes = [
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      title: "登录",
      keepAlive: true, // 需要被缓存
    },
  },
  {
    path: "/",
    name: "index",
    component: Index,
    meta: {
      title: "畅融通-小微融资",
      keepAlive: true, // 需要被缓存
    },
  },
  {
    path: "/health",
    name: "health",
    component: Health,
    meta: {
      title: "健康检查",
    },
  },
  {
    path: "/appAgreementOne",
    name: "appAgreementOne",
    component: appAgreementOne,
    meta: {
      title: "APP用户服务协议",
    },
  },
  {
    path: "/privacyAgreement",
    name: "privacyAgreement",
    component: privacyAgreement,
    meta: {
      title: "隐私协议",
    },
  },
  {
    path: "/informationShare",
    name: "informationShare",
    component: informationShare,
    meta: {
      title: "信息共享授权书",
    },
  },
  {
    path: "/carLoanAgreeemnt",
    name: "carLoanAgreeemnt",
    component: carLoanAgreeemnt,
    meta: {
      title: "信息共享授权书",
    },
  },
  {
    path: "/csssLoanAgreeemnt",
    name: "csssLoanAgreeemnt",
    component: csssLoanAgreeemnt,
    meta: {
      title: "信息共享授权书",
    },
  },
  {
    path: "/toBorrow", // 去掉后面默认拼接的参数
    name: "toBorrow",
    component: toBorrow,
    meta: {
      title: "去借款",
    },
  },
  {
    path: "/toProgressDetailAPI",
    name: "toProgressDetailAPI",
    component: toProgressDetailAPI,
    meta: {
      title: "进展详情",
    },
  },
  // 进件结果页；线索模式
  {
    path: "/toProgressResult",
    name: "toProgressResult",
    component: toProgressResult,
    meta: {
      title: "进件结果页",
    },
  },
  {
    path: "/toAuthentication",
    name: "toAuthentication",
    component: toAuthentication,
    meta: {
      title: "借款信息",
    },
  },
  {
    path: "/toAuthenticAPI",
    name: "toAuthenticAPI",
    component: toAuthenticAPI,
    meta: {
      title: "基本信息",
      keepAlive: true, // 需要被缓存
    },
  },
  {
    path: "/middlePage",
    name: "middlePage",
    component: middlePage,
    meta: {
      title: "去借款",
    },
  },
  {
    path: "/myLoan",
    name: "myLoan",
    component: myLoan,
    meta: {
      title: "我的",
    },
  },
  {
    path: "/toAuthentiForNative",
    name: "toAuthentiForNative",
    component: toAuthentiForNative,
    meta: {
      title: "借款资料页",
    },
  },
  // agreementList
  {
    path: "/agreementList",
    name: "agreementList",
    component: agreementList,
    meta: {
      title: "用户协议",
    },
  },
  // aboutMe
  {
    path: "/aboutMe",
    name: "aboutMe",
    component: aboutMe,
    meta: {
      title: "关于我们",
    },
  },
  // helpCenter
  {
    path: "/helpCenter",
    name: "helpCenter",
    component: helpCenter,
    meta: {
      title: "帮助中心",
    },
  },
  // cancelAccount
  {
    path: "/cancelAccount",
    name: "cancelAccount",
    component: cancelAccount,
    meta: {
      title: "注销账号",
    },
  },
  {
    path: '/pcHome',
    name: 'pcHome',
    component: pcHome,
    meta: {
      title: '畅融通'
    }
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_PUBLIC_BASE,
  routes,
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => err)
}

router.beforeEach((to, from, next) => {
  const domain = window.location.hostname
  if (domain === 'www.changrt.com' || domain === 'changrt.com') {
    if (to.name !== 'pcHome') {
      next({ path: '/pcHome' })
    } else {
      next()
    }
  }
  // 如果是要进入认证页面并且没有登录的话，就进入登录页面

  // 页面跳转的时候都加上这个参数
  const title = to.meta && to.meta.title
  if (title) {
    document.title = title
  }
  next()
})

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  console.log(
    isChunkLoadFailed,
    "/Loading chunk (d)+ failed/g",
    "路由懒加载找不到对应的moudle"
  )
  if (isChunkLoadFailed) {
    router.replace(targetPath)
  } else {
    console.log(error)
  }
})

export default router
