<template>
    <van-overlay :show="$store.state.loading">
        <van-loading class="hc-loading-wrapper" :size="40" vertical>
        {{loadingText}}
        </van-loading>
    </van-overlay>
</template>
<script>
import { Overlay, Loading } from 'vant'
export default {
  name: 'hc-loading',
  components: {
    [Overlay.name]: Overlay,
    [Loading.name]: Loading
  },
  computed: {
    loadingText () {
      return this.$store.state.loadingText
    }
  }
}
</script>
<style>
.hc-loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>

