/**
 * @file home入口文件
 * @author dongkunshan(dongkunshan210816@credithc.com)
 */

// core js
import Vue from "vue"
import app from "./app.vue"
import "lib-flexible"
import router from "./router"
import store from "./store"
import Vconsole from "vconsole"
import { init,isNativeApp } from '@/utils/bridge'
import Bridge from '@/utils/bridge'
import { Dialog, Toast } from "vant"
import mixins from "../../mixins"
import '@/assets/fonts/DINBold.ttf'
import preventReClick from '@/utils/preventReClick'
if (
  process.env.VUE_APP_ENV === "development"
  || process.env.VUE_APP_ENV.indexOf("test") > -1
) {
  const vConsole = new Vconsole()
  Vue.use(vConsole)
}
Vue.filter('thousands', function (value) {
  if (!value) {return ''}
  return value.toLocaleString()
})

if (isNativeApp()) {
  init()
}
Vue.prototype.$bridge=Bridge
Vue.mixin(mixins)
Vue.use(preventReClick)
Vue.use(Dialog)
Vue.use(Toast)
new Vue({
  store,
  router,
  render: (h) => h(app),
}).$mount("#app")
