<template>
  <div>
    <template v-if="!$store.state.showNoSignal">
      <keep-alive :include="include">
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
      <hc-loading></hc-loading>
    </template>
    <!-- 没有信号 -->
    <template v-else>
      <Abnormal />
    </template>
  </div>
</template>

<script>
import HcLoading from '@/views/index/components/HcLoading.vue'
import Abnormal from '@/views/index/components/Abnormal.vue'

export default {
  name: 'index',
  components: {
    HcLoading,
    Abnormal
  },
  data() {
    return {
      active: 'index',
      include: [],
    }
  },
  watch: {
    '$route': function (newVal, oldVal) {
      this.active = newVal.name
      // 如果 要 to(进入) 的页面是需要 keepAlive 缓存的，把 name push 进 include数组
      if (newVal.meta.keepAlive) {
        !this.include.includes(newVal.name) && this.include.push(newVal.name)
      }
      // 如果进入的页面不是协议页面，也不是去借款页面，去掉缓存，否则保留缓存。
      if (oldVal.meta.keepAlive && this.removeKeepAlive(newVal.name)) {
        let index = this.include.indexOf(oldVal.name)
        index !== -1 && this.include.splice(index, 1)
      }
    },
  },
  methods: {
    removeKeepAlive (name) {
      // 如果进入的页面不是协议页面，也不是去借款页面，去掉缓存
      return !name.includes('Agreement') && name !== 'toBorrow'
    }
  }
}
</script>

<style lang="scss">
  @import '../../assets/style/common';
  @import '../../assets/style/mixin';
  @import './style/index';
  /* 声明全局字体样式 */
  @font-face {
    font-family: 'DINfont'; /* 字体名称 */
    src: url('../../assets/fonts/DINBold.ttf'); /* 字体文件相对路径 */
  }
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    margin-top: 60px;
    .nav-a {
      color: #c0ccda;
      text-decoration: none;
    }

    .subnav-a {
      color: #666;
      text-decoration: none;
    }

    .el-submenu__icon-arrow {
      display: none;
    }
  }
</style>
