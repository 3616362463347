var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.$store.state.showNoSignal
        ? [
            _c(
              "keep-alive",
              { attrs: { include: _vm.include } },
              [_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e()],
              1
            ),
            _vm._v(" "),
            !_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e(),
            _vm._v(" "),
            _c("hc-loading"),
          ]
        : [_c("Abnormal")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }